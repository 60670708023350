import { computed, ref } from '@vue/composition-api'
import { webhookTypeService } from '../services'

export default () => {
  const types = ref([])

  const typeOptions = computed(() =>
    types.value.map(d => ({
      label: `${d.id} (${d.name})`,
      value: d.id,
    })),
  )

  return {
    types,
    typeOptions,
    fetchWebhookTypes: query =>
      webhookTypeService.index(query).then(({ records }) => {
        types.value = records

        return types.value
      }),
  }
}

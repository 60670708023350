import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/webhook-types', {
    params,
  })
const show = id => axios.get(`/admin/webhook-types/${id}`)

export default {
  index,
  show,
}

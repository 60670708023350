<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- data_id -->
      <template #[`item.data_id`]="{ item }">
        <div>#{{ item.data_id }} - {{ getItemDisplayText(item) }}</div>
        <small>{{ item.type.name }}</small>
      </template>

      <template #[`item.sent_at`]="{ item }">
        {{ formatDate(item.sent_at) }}
        <span v-if="item.attempts">({{ item.attempts }})</span>
        <small v-if="item.response_ms"> - {{ item.response_ms }}ms</small>
      </template>

      <template #[`item.last_response`]="{ item }">
        <div style="text-wrap: balance; max-height: 5rem; overflow: hidden; max-width: 550px;">
          {{ item.last_response }}
        </div>
        <small v-if="item.failures.length > 1">more failures: {{ item.failures.length }} - see Network Tab</small>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="resource">
                <resend-dialog
                  v-if="$can('webhook_event:resend', 'webhook_event_management')"
                  :resource="resource"
                  @updated="paginate"
                />
              </template>

              <!-- Required to hold some space -->
              <v-list-item
                v-else
                style="min-width: 100px;"
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { formatDate } from '@core/utils/filter'
import { mdiDelete, mdiDotsVertical } from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import ResendDialog from './ResendDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    ResendDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Data ID', value: 'data_id' },
      { text: 'Sent At', value: 'sent_at' },
      { text: 'Last Response', value: 'last_response' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const getItemDisplayText = item => {
      const { data } = item

      return data?.slug || data?.order_number || data?.checkout_number
    }

    return {
      getItemDisplayText,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiDelete,
      },
    }
  },
}
</script>

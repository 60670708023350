<template>
  <v-select
    v-model="typeId"
    placeholder="Webhook Type"
    :items="typeOptions"
    item-text="label"
    item-value="value"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
  </v-select>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import useWebhookType from '../../composables/useWebhookType'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { typeOptions, fetchWebhookTypes } = useWebhookType()

    fetchWebhookTypes({ take: 999 })

    const typeId = ref(props.filters.type_id)

    watch(typeId, () => emit('changed', {
      ...props.filters,
      type_id: typeId.value,
    }))

    return {
      typeOptions,
      typeId,
    }
  },
}
</script>

<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>Resend</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>
        <span>Warning</span>
      </v-card-title>
      <v-card-text>
        <p>Are you sure? This may trigger some actions duplication!</p>
        <p>Ask the developer for clarifications before resending this event!</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="onResend"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiDatabaseEdit }}
            </v-icon>
            <span>
              RESEND
            </span>
          </v-btn>
        </div>
        <div>
          <v-btn
            color="secondary"
            @click="onClose"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiArrowLeft }}
            </v-icon>
            <span>
              BACK
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import {
mdiArrowLeft,
mdiRefresh,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { webhookEventService } from '../services'

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onResend = async data => {
      loading.value = true

      await webhookEventService.resend(props.resource.id).then(() => {
        isDialogVisible.value = false

        emit('updated')

        useNotifySuccess({
          content: 'WebhookEvent has been resend successfully.',
        })
      }).catch(error => {
        useNotifyErrors(error)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onResend,

      loading,

      icons: {
        mdiRefresh,
        mdiArrowLeft,
      },
    }
  },
}
</script>

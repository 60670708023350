import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/webhook-events', {
    params,
  })
const show = id => axios.get(`/admin/webhook-events/${id}`)
const resend = id => axios.post(`/admin/webhook-events/${id}/resend`)

export default {
  index,
  show,
  resend,
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.data_id",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("#"+_vm._s(item.data_id)+" - "+_vm._s(_vm.getItemDisplayText(item)))]),_c('small',[_vm._v(_vm._s(item.type.name))])]}},{key:"item.sent_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.sent_at))+" "),(item.attempts)?_c('span',[_vm._v("("+_vm._s(item.attempts)+")")]):_vm._e(),(item.response_ms)?_c('small',[_vm._v(" - "+_vm._s(item.response_ms)+"ms")]):_vm._e()]}},{key:"item.last_response",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-wrap":"balance","max-height":"5rem","overflow":"hidden","max-width":"550px"}},[_vm._v(" "+_vm._s(item.last_response)+" ")]),(item.failures.length > 1)?_c('small',[_vm._v("more failures: "+_vm._s(item.failures.length)+" - see Network Tab")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadResource(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[(_vm.resource)?[(_vm.$can('webhook_event:resend', 'webhook_event_management'))?_c('resend-dialog',{attrs:{"resource":_vm.resource},on:{"updated":_vm.paginate}}):_vm._e()]:_c('v-list-item',{staticStyle:{"min-width":"100px"}})],2)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }